import classNames from "classnames";
import React from "react";
import { useScale } from "./Infographic";

export interface StatisticDetails {
  prefix?: string;
  precision?: number;
  amount: number;
  unit?: string;
  name?: string;
  message?: string;
}

interface Props {
  title?: string;
  statistics?: StatisticDetails[];
  className?: string;
}

export const Statistics: React.FC<Props> = ({
  title,
  statistics,
  className,
}) => {
  const [sectionRef, scale] = useScale();

  if (!title && !statistics) {
    return null;
  }
  return (
    <section
      data-component="Statistics"
      ref={sectionRef}
      className={classNames("relative page-grid max-width z-10", className)}
    >
      <div className="py-section col-start-2 col-span-12 content-only-grid">
        {title && (
          <h2 className="p1 col-start-2 col-span-10 text-center mb-75px">
            {title}
          </h2>
        )}
        {statistics?.map((statistic, index) => {
          const isOdd = index % 2 === 1;
          const { prefix, amount, precision, unit, name, message } = statistic;
          const realPrecision = precision || 1;
          return (
            <div
              key={index}
              className={classNames(
                "col-span-12 lg:col-span-4 py-75px bg-gradient-to-b from-light-blue",
                isOdd && "mt-60px"
              )}
            >
              <h3 className="h1 tabular-nums text-center">
                {prefix}
                {Math.round(amount * scale * realPrecision) / realPrecision}
                {unit}
              </h3>
              {name && (
                <h4 className="px-60px break-words h3 text-center">{name}</h4>
              )}
              {message && (
                <p className="pt-48px p3 text-center px-40px">{message}</p>
              )}
            </div>
          );
        })}
      </div>
    </section>
  );
};
