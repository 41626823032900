import classNames from "classnames";
import React, { useEffect, useRef } from "react";
interface Props {
  name?: string;
  className?: string;
}
export const LogoDes: React.FC<Props> = ({ className }) => {
  const sectionRef = useRef<HTMLElement>(null);
  useEffect(() => {
    // const ref = sectionRef.current;
  }, []);
  return (
    <section
      data-component="LogoDes"
      className={classNames(
        "relative w-full page-grid max-width z-10 ",
        className ? className : "py-2.5"
      )}
      ref={sectionRef}
    >
      <h3 className="h7 col-start-2 col-span-12 text-center leading-tight pb-5">
        Our Energy Transition Practice in the Media
      </h3>
      <br />
      <br />
      <h5 className="col-start-2 col-span-12 text-center leading-tight">
        Read our latest discussions in the media about the road to net zero
      </h5>
    </section>
  );
};
