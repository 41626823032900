import React from "react";
import { PeopleCollection } from ".";
import { ProfileInformation, SingleProfile } from "./SingleProfile";

interface Props {
  title?: string;
  offset?: boolean;
  profiles: ProfileInformation[];
}
export const SectorLeadership: React.FC<Props> = ({
  title,
  offset,
  profiles,
}) => {
  return (
    <PeopleCollection
      title={title}
      offset={offset}
      contents={profiles.map((profile) => (
        <SingleProfile
          key={profile.name}
          {...profile}
          showCountry={true}
          showTitle={true}
          showQuote={true}
          hoverExpand={true}
        />
      ))}
    />
  );
};
